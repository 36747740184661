@font-face {
  font-family: 'Helvetica Neue LT W05_45 Light';
  src: url('./font-light/font-light.eot') format('embedded-opentype'),
    url('./font-light/font-light.woff') format('woff'),
    url('./font-light/font-light.ttf') format('truetype'),
    url('./font-light/font-light.svg') format('svg');
}

@font-face {
  font-family: 'Helvetica Neue LT W05_55 Roman';
  src: url('./font-medium/font-medium.eot') format('embedded-opentype'),
    url('./font-medium/font-medium.woff') format('woff'),
    url('./font-medium/font-medium.ttf') format('truetype'),
    url('./font-medium/font-medium.svg') format('svg');
}

@font-face {
  font-family: 'Helvetica Neue LT W05_65 Medium';
  src: url('./font-normal/font-normal.eot') format('embedded-opentype'),
    url('./font-normal/font-normal.woff') format('woff'),
    url('./font-normal/font-normal.ttf') format('truetype'),
    url('./font-normal/font-normal.svg') format('svg');
}

@font-face {
  font-family: 'Helvetica Neue LT W01_75 Bold';
  src: url('./font-bold/font-bold.eot') format('embedded-opentype'),
    url('./font-bold/font-bold.woff') format('woff'),
    url('./font-bold/font-bold.ttf') format('truetype'),
    url('./font-bold/font-bold.svg') format('svg');
}

@font-face {
  font-family: 'Verdana';
  src: url('./verdana/VerdanaW01-Regular.eot');
  src: url('./verdana/VerdanaW01-Regular.eot?#iefix') format('embedded-opentype'),
    url('./verdana/VerdanaW01-Regular.woff2') format('woff2'),
    url('./verdana/VerdanaW01-Regular.woff') format('woff'),
    url('./verdana/VerdanaW01-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Verdana Bold';
  src: url('./verdana/VerdanaW01-Bold.eot');
  src: url('./verdana/VerdanaW01-Bold.eot?#iefix') format('embedded-opentype'),
    url('./verdana/VerdanaW01-Bold.woff2') format('woff2'),
    url('./verdana/VerdanaW01-Bold.woff') format('woff'),
    url('./verdana/VerdanaW01-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Light/font.eot');
  font-family: 'CeraPro-Light';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Light/font.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Light/font.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Light/font.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Light/font.ttf')
      format('truetype');
}
@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPRO-Black/font.eot');
  font-family: 'CeraPRO-Black';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPRO-Black/font.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPRO-Black/font.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPRO-Black/font.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPRO-Black/font.ttf')
      format('truetype');
}
@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPRO-Bold/font.eot');
  font-family: 'CeraPRO-Bold';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPRO-Bold/font.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPRO-Bold/font.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPRO-Bold/font.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPRO-Bold/font.ttf')
      format('truetype');
}
@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-BlackItalic/font.eot');
  font-family: 'CeraPro-BlackItalic';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-BlackItalic/font.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-BlackItalic/font.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-BlackItalic/font.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-BlackItalic/font.ttf')
      format('truetype');
}
@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-BoldItalic/font.eot');
  font-family: 'CeraPro-BoldItalic';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-BoldItalic/font.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-BoldItalic/font.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-BoldItalic/font.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-BoldItalic/font.ttf')
      format('truetype');
}
@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-RegularItalic/font.eot');
  font-family: 'CeraPro-RegularItalic';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-RegularItalic/font.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-RegularItalic/font.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-RegularItalic/font.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-RegularItalic/font.ttf')
      format('truetype');
}
@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-ThinItalic/font.eot');
  font-family: 'CeraPro-ThinItalic';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-ThinItalic/font.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-ThinItalic/font.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-ThinItalic/font.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-ThinItalic/font.ttf')
      format('truetype');
}
@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Thin/font.eot');
  font-family: 'CeraPro-Thin';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Thin/font.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Thin/font.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Thin/font.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Thin/font.ttf')
      format('truetype');
}
@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Regular/font.eot');
  font-family: 'CeraPro-Regular';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Regular/font.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Regular/font.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Regular/font.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Regular/font.ttf')
      format('truetype');
}
@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-MediumItalic/font.eot');
  font-family: 'CeraPro-MediumItalic';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-MediumItalic/font.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-MediumItalic/font.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-MediumItalic/font.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-MediumItalic/font.ttf')
      format('truetype');
}
@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Medium/font.eot');
  font-family: 'CeraPro-Medium';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Medium/font.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Medium/font.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Medium/font.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-Medium/font.ttf')
      format('truetype');
}
@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-LightItalic/font.eot');
  font-family: 'CeraPro-LightItalic';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-LightItalic/font.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-LightItalic/font.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-LightItalic/font.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/CeraPro-Web/CeraPro-LightItalic/font.ttf')
      format('truetype');
}

@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Small.eot');
  font-family: 'EksellDisplayWeb-Small';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Small.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Small.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Small.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Small/font.ttf')
      format('truetype');
}

@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Medium.eot');
  font-family: 'EksellDisplayWeb-Medium';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Medium.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Medium.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Medium/font.ttf')
      format('truetype');
}

@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Large.eot');
  font-family: 'EksellDisplayWeb-Large';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Large.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Large.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Large.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Large/font.ttf')
      format('truetype');
}

@font-face {
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Stencil.eot');
  font-family: 'EksellDisplayWeb-Stencil';
  src: url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Stencil.woff2')
      format('woff2'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Stencil.woff')
      format('woff'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Stencil.eot?#iefix')
      format('embedded-opentype'),
    url('https://hartela101.blob.core.windows.net/organisation-resources/fonts/EksellDisplay-Web/EksellDisplayWeb-Stencil/font.ttf')
      format('truetype');
}

@font-face {
  font-family: "FK Grotesk Neue Light";
  src:  url("./fkgroteskneue/FoundersGrotesk-Light.otf") format("opentype");
}

@font-face {
  font-family: "FK Grotesk Neue Regular";
  src:  url("./fkgroteskneue/FoundersGrotesk-Light.otf") format("opentype");
}

@font-face {
  font-family: "FK Grotesk Neue";
  src: url("./fkgroteskneue/FKGroteskNeue-Medium.eot") format("embedded-opentype"),
    url("./fkgroteskneue/FKGroteskNeue-Medium.woff2") format("woff2"),
    url("./fkgroteskneue/FKGroteskNeue-Medium.ttf") format("truetype"),
    url("./fkgroteskneue/FKGroteskNeue-Medium.svg") format("svg"),
    url("./fkgroteskneue/FKGroteskNeue-Medium.otf") format("opentype");
}

@font-face {
  font-family: 'FK Grotesk Neue Bold';
  src: url('./fkgroteskneue/FKGroteskNeue-Bold.eot') format('embedded-opentype'),
    url('./fkgroteskneue/FKGroteskNeue-Bold.woff2') format('woff2'),
    url('./fkgroteskneue/FKGroteskNeue-Bold.ttf') format('truetype'),
    url('./fkgroteskneue/FKGroteskNeue-Bold.svg') format('svg'),
    url('./fkgroteskneue/FKGroteskNeue-Bold.otf') format('opentype');
}
